document.addEventListener("DOMContentLoaded", function() {
    // Sélectionner le formulaire
    const formWaterHeater = document.querySelector('.form-water-heater .no-reference');

    if(formWaterHeater){
        const formRadioElements = formWaterHeater.querySelectorAll('input[type="radio"]');
        // Fonction pour envoyer les données du formulaire via XHR
        initRadioAttr(formRadioElements);

        function initRadioAttr(formRadioElements){
            formRadioElements.forEach(function(element) {
                if(element.checked){
                    element.dataset.checked = "true";
                }else{
                    element.dataset.checked = "false";
                }
            });
        }

        function sendSelectedData() {
            const selectedData = {};

            // Sélectionner tous les éléments input, select, etc., dans la div
            const formElements = formWaterHeater.querySelectorAll('input, select, textarea');

            // Parcourir chaque champ dans la div
            formElements.forEach(function(element) {
                // Vérifier si le champ est sélectionné ou a une valeur modifiée
                if ((element.type === 'checkbox' || element.type === 'radio') && element.checked) {
                    selectedData[element.name] = element.value;
                } else if (element.tagName === 'SELECT' && element.selectedIndex !== -1) {
                    // Si c'est un champ select et une option est sélectionnée
                    selectedData[element.name] = element.value;
                } else if (element.type !== 'radio' && element.type !== 'checkbox' && element.value.trim() !== '') {
                    // Si c'est un autre type de champ et qu'il a une valeur non vide
                    selectedData[element.name] = element.value;
                }
            });

            // Si des données sélectionnées existent, les envoyer
            if (Object.keys(selectedData).length > 0) {
                const xhr = new XMLHttpRequest();

                // Ouvrir la requête XHR
                xhr.open('POST', '/update_water_heater_form_filters', true);
                xhr.setRequestHeader('Content-Type', 'application/json');

                // Définir un comportement en cas de succès ou d'échec
                xhr.onload = function() {
                    if (xhr.status === 200) {
                        // Mettre à jour les champs du formulaire avec la réponse
                        const response = JSON.parse(xhr.responseText);
                        updateFormFields(response);
                        console.log('Les données sélectionnées ont été envoyées avec succès');
                    } else {
                        console.log('Une erreur est survenue lors de l\'envoi des données');
                    }
                };

                // Envoi des données sélectionnées sous forme de JSON
                xhr.send(JSON.stringify(selectedData));
            }
        }

        // Fonction pour mettre à jour les champs du formulaire avec la réponse du serveur
        function updateFormFields(response) {
            // Parcourir chaque élément de réponse pour mettre à jour les champs du formulaire
            for (const fieldName in response.form) {
                const fieldData = response.form[fieldName];
                const fieldValue = response[fieldName];
                const field = formWaterHeater.querySelector(`[name="${fieldName}"]`);

                if (field) {
                    if (field.tagName === 'SELECT') {
                        // Si le champ est un SELECT, mettre à jour les options
                        updateSelectField(field, fieldData, fieldValue);
                    } else if (field.type === 'checkbox' || field.type === 'radio') {
                        // Si le champ est un checkbox ou un radio, mettre à jour les options
                        updateCheckboxRadioField(field, fieldData, fieldValue);
                    } else {
                        // Si le champ est un autre type (par exemple, texte), on peut juste définir la valeur
                        field.value = fieldData;
                    }
                }
            }
        }

        // Fonction pour mettre à jour un champ SELECT
        function updateSelectField(selectElement, options, fieldValue) {
            // Conserver la première option (l'option vide)
            const firstOption = selectElement.querySelector('option');
            if(fieldValue !== ''){
                selectElement.dataset.initialValue = firstOption.innerHTML;
                firstOption.innerHTML = 'Réinitialiser le filtre';
            }else if(selectElement.dataset.initialValue){
                firstOption.innerHTML = selectElement.dataset.initialValue;
            }

            // Supprimer toutes les autres options (sauf la première)
            const optionsToRemove = selectElement.querySelectorAll('option:not(:first-child)');
            optionsToRemove.forEach(option => option.remove());

            // Ajouter la première option (l'option vide)
            selectElement.appendChild(firstOption);

            // Ajouter les nouvelles options
            for (const [key, value] of Object.entries(options)) {
                const optionElement = document.createElement('option');
                optionElement.value = key;
                optionElement.textContent = value;
                if(fieldValue === key ){
                    optionElement.selected = true;
                }
                selectElement.appendChild(optionElement);
            }
        }

        // Fonction pour mettre à jour un champ checkbox ou radio
        function updateCheckboxRadioField(fieldElement, options, fieldValue) {
            // Réinitialiser l'état des éléments
            const elements = formWaterHeater.querySelectorAll(`[name="${fieldElement.name}"]`);
            elements.forEach(element => {
                const elementLabel = formWaterHeater.querySelector(`label[for="${element.id}"]`);
                element.checked = false; // Désélectionner tous les éléments
                element.disabled = 'disabled'; // Désélectionner tous les éléments
                elementLabel.style.opacity = 0.5; // Masquer tous les éléments
            });

            // Sélectionner les éléments en fonction des données retournées
            for (const [key, value] of Object.entries(options)) {
                const matchedElement = formWaterHeater.querySelector(`[name="${fieldElement.name}"][value="${value}"]`);
                const matchedElementLabel = matchedElement.labels[0];
                if (matchedElement) {
                    matchedElement.removeAttribute("disabled");
                    matchedElementLabel.style.opacity = 1;
                    if(fieldValue === value){
                        matchedElement.checked = true;
                    }
                }
            }
        }

        formRadioElements.forEach(function(element) {
            element.addEventListener('click', function(event) {
                if(element.checked && element.dataset.checked === "false" && element === event.target){
                    element.dataset.checked = "true";
                }else if(element.checked && element.dataset.checked === "true" && element === event.target){
                    element.dataset.checked = "false";
                    element.checked = false;
                }else if(!element.checked && element.dataset.checked === "false" && element === event.target){
                    element.dataset.checked = "true";
                    element.checked = true;
                }else if(!element.checked && element.dataset.checked === "true" && element === event.target){
                    element.dataset.checked = "false";
                }
                sendSelectedData();
            });
        });

        formWaterHeater.addEventListener('change', function(event) {
            sendSelectedData();
        });
    }
});