import $ from "jquery";

class MaxxingLib {
  constructor({assetBaseUrl, uiElement} = {}) {
    // Valeur par défaut pour assetBaseUrl
    this.assetBaseUrl = assetBaseUrl || '/bundles/thermorprosite/images/maxxingLib/';
    
    // Si uiElement est fourni, on cherche soit une classe (.) soit un ID (#) sinon on prend la valeur par défaut
    if (uiElement) {
      // Vérification si c'est un ID ou une classe
      if (uiElement.startsWith('#')) {
        this.thermorMasterHeader = document.querySelectorAll(uiElement);  // Sélection par ID
      } else if (uiElement.startsWith('.')) {
        this.thermorMasterHeader = document.querySelectorAll(uiElement);  // Sélection par classe
      } else {
        console.error('Le sélecteur fourni doit être une classe (commençant par ".") ou un ID (commençant par "#").');
        this.thermorMasterHeader = [];
      }
    } else {
      // Valeur par défaut si aucun uiElement n'est fourni
      this.thermorMasterHeader = document.querySelectorAll('.maxxingContainer');
    }
    
    // Injection des éléments DOM dans chaque container trouvé
    if (this.thermorMasterHeader.length > 0) {
      this.thermorMasterHeader.forEach((item) => {
        try {
          this.initData(item);  // Appel de la méthode pour initialiser les données
        } catch (error) {
          console.error('Erreur lors de l’initialisation du conteneur:', error);
        }
      });
    } else {
      console.error('Aucun élément UI valide trouvé pour thermorMasterHeader.');
    }
    
  
  }
  
  
  // Méthode pour injecter les éléments DOM nécessaires
  
  injectDOMElements(container) {
    if (!container) throw new Error('Le conteneur est manquant');
    // Sélectionner le div contenant le <a>
    const flexContainer = container.querySelector('.maxxingContainer-wrapper');
    
    // Vérifier que le <a> existe déjà
    const linkElement = flexContainer.querySelector('.maxxingContainer-logo');
    
    // Insérer les nouveaux éléments après le lien <a> s'ils n'existent pas déjà
    if (linkElement) {
      // Vérifier si 'EngagementProgramHeader--status' n'existe pas déjà
      if (!flexContainer.querySelector('.EngagementProgramHeader--status')) {
        const statusButton = document.createElement('button');
        statusButton.className = 'EngagementProgramHeader--status';
        statusButton.innerHTML = `
        <p class="EngagementProgramHeader--statusName"></p>
        <span class="EngagementProgramHeader--statusLogo"></span>
      `;
        // Insérer après l'élément <a>
        linkElement.insertAdjacentElement('afterend', statusButton);
      }
      
      // Vérifier si 'EngagementProgramHeader--niveau' n'existe pas déjà
      if (!flexContainer.querySelector('.EngagementProgramHeader--niveau')) {
        const niveauDiv = document.createElement('div');
        niveauDiv.className = 'EngagementProgramHeader--niveau';
        niveauDiv.innerHTML = `
        <div class="EngagementProgramHeader--xp d-flex align-items-center">
          <label class="desktop-level"></label>
          <label class="mob-level"></label>
          <div class="progress-container">
            <div class="progress-level" id="progress-level"></div>
          </div>
        </div>
        <div class="EngagementProgramHeader--lingot" data-nextlevel="0">
          <span></span> <img src="${this.assetBaseUrl}union.svg">
        </div>
        <div class="cxp-maxxing" data-currentlevel="0"></div>
      `;
        // Insérer après le bouton de statut
        flexContainer.querySelector('.EngagementProgramHeader--status')
          .insertAdjacentElement('afterend', niveauDiv);
      }
      
      // Vérifier si 'EngagementProgramHeader--lango' n'existe pas déjà
      if (!flexContainer.querySelector('.EngagementProgramHeader--lango')) {
        const langoButton = document.createElement('button');
        langoButton.className = 'EngagementProgramHeader--lango';
        langoButton.innerHTML = `
        <p class="EngagementProgramHeader--langoName"> <span></span>  </p>
        <span class="EngagementProgramHeader--langoLogo">
            <img src="${this.assetBaseUrl}lango.svg">
        </span>
        <div class="lingot-maxxing" data-lingot="0"></div>
      `;
        // Insérer après la div 'EngagementProgramHeader--niveau'
        flexContainer.querySelector('.EngagementProgramHeader--niveau')
          .insertAdjacentElement('afterend', langoButton);
      }
    }
    if (!container.querySelector('.infoBull--wrapper')) {
      const infoBullWrapper = document.createElement('div');
      infoBullWrapper.className = 'infoBull--wrapper';
      
      // Ajouter le contenu initial
      infoBullWrapper.innerHTML = `
      <div class="infoBull--logo"></div>
      <div class="infoBull--desc d-none">
          <p>Vous bénéficiez d'un super pouvoir.</p>
          <p>Chaque aventure rapporte</p>
          <div class="d-flex justify-content-center mt-2 gap-2">
              <div class="infoBull--xp d-flex gap-2">
                  <img src="${this.assetBaseUrl}/union.svg" />
              </div>
              <div class="infoBull--lango d-flex gap-2">
                  <img src="${this.assetBaseUrl}/lango.svg" />
              </div>
          </div>
      </div>
    `;
      container.appendChild(infoBullWrapper);
    }
    // Ajouter les gestionnaires d'événements après l'insertion des éléments
    const statusButtons = flexContainer.querySelectorAll('.EngagementProgramHeader--status');
    if (statusButtons.length > 0) {
      statusButtons.forEach((item) => {
        item.addEventListener('click', function () {
          const infoBullWrapper = container.querySelector('.infoBull--wrapper');
          if (infoBullWrapper) {
            infoBullWrapper.classList.toggle("show");
          }
        });
      });
    }
  }
  
  // Méthode générique pour récupérer les données
  fetchData() {
    return fetch('/info-maxxing', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest'
      },
    })
      .then(response => response.json())
      .catch(error => {
        console.error('Erreur lors de la récupération des données:', error);
        throw error; // Lancer l'erreur pour la gérer dans la méthode appelante
      });
  }
  
  initData(itemDom) {
    this.fetchData()
      .then(data => {
        this.injectDOMElements(itemDom);  // Appel d'une méthode pour injecter les éléments DOM
        this.thermorMasterHeader.forEach((item) => {
          // Récupération des éléments DOM, évitant de répéter les requêtes
          const nv = item.querySelector('.EngagementProgramHeader--niveau .desktop-level');
          const nvMb = item.querySelector('.EngagementProgramHeader--niveau .mob-level');
          const counterValue = item.querySelector('.EngagementProgramHeader--lingot span');
          const statusMaxxing = item.querySelector('.EngagementProgramHeader--status .EngagementProgramHeader--statusName');
          const progressLevels = item.querySelector('.progress-level');
          const lingot = item.querySelector('.EngagementProgramHeader--lango .EngagementProgramHeader--langoName span');
          const logoStatusMaxxingContainer = item.querySelector('.EngagementProgramHeader--statusLogo');
          let infoBullLogoContainer = item.querySelector('.infoBull--logo');
          const infoBullDesc = item.querySelector('.infoBull--desc ');
          const infoBullXp = item.querySelector('.infoBull--desc .infoBull--xp');
          const infoBullLingot = item.querySelector('.infoBull--desc .infoBull--lango');
  
          // Vérifier si toutes les variables DOM requises existent
          if (nv && nvMb && counterValue && statusMaxxing && progressLevels && lingot && logoStatusMaxxingContainer && infoBullLogoContainer && infoBullDesc && infoBullXp && infoBullLingot) {
            // Initialisation Level
            this.initLevel(nv, nvMb, progressLevels, data);
    
            // Initialisation Lingots
            this.InitLingout(lingot, data);
    
            // Initialisation XP
            this.initXP(counterValue, data);
    
            // Initialisation Statut
            this.initStatus(statusMaxxing, infoBullLogoContainer, infoBullXp, infoBullLingot, logoStatusMaxxingContainer, data, infoBullDesc);
          } else {
            console.warn("Un ou plusieurs éléments DOM sont manquants, initialisation ignorée pour cet élément.");
          }
        });
        
      });
    
  }
  
  initLevel(nv, nvMb, progressLevels, data) {
    try {
      // Vérification et conversion des données pour éviter les erreurs
      const xp = isNaN(parseFloat(data.C_XP)) ? 0 : parseFloat(data.C_XP);
      const nextLevel = isNaN(parseFloat(data.C_NEXTLEVEL)) ? 0 : parseFloat(data.C_NEXTLEVEL);
      const niveau = isNaN(parseInt(data.NIVEAU)) ? 0 : parseInt(data.NIVEAU);
      
      // Calcul du nouveau niveau de la barre de progression
      const newNiveauBar = nextLevel === 0 ? 0 : Math.floor((xp / (xp + nextLevel)) * 100);
      
      // Vérification de l'existence des éléments DOM avant la mise à jour
      if (nv) {
        nv.textContent = "Niveau " + niveau;
        nv.setAttribute('data-level', niveau);
      } else {
        console.error("L'élément nv est invalide ou non défini.");
      }
      
      if (nvMb) {
        nvMb.textContent = "N." + niveau + "-";
      } else {
        console.error("L'élément nvMb est invalide ou non défini.");
      }
      
      // Mise à jour de la barre de progression
      if (progressLevels) {
        this.updateProgressBar(progressLevels, newNiveauBar);
      } else {
        console.error("L'élément progressLevels est invalide ou non défini.");
      }
    } catch (error) {
      console.error("Une erreur s'est produite lors de l'initialisation du niveau :", error);
    }
  }
  
  updateLevel(dom, domMob, newLavel, progressLevels, newNiveauBar) {
    if (dom) {
      dom.textContent = "Niveau " + newLavel;
      dom.setAttribute('data-level', newLavel);
    } else {
      console.error("L'élément nv est invalide ou non défini.");
    }
    
    if (domMob) {
      domMob.textContent = "N." + newLavel + "-";
    } else {
      console.error("L'élément nvMb est invalide ou non défini.");
    }
    // Mise à jour de la barre de progression
    if (progressLevels) {
      this.updateProgressBar(progressLevels, newNiveauBar);
    } else {
      console.error("L'élément progressLevels est invalide ou non défini.");
    }
    
  }
  
  InitLingout(lingot, data) {
    try {
      // Vérification de la validité de la donnée C_LINGOT et assignation d'une valeur par défaut si nécessaire
      const lingotValue = isNaN(parseFloat(data.C_LINGOT)) ? 0 : Math.round(parseFloat(data.C_LINGOT));
      
      // Mise à jour du textContent et de l'attribut data-lingot si l'élément lingot existe
      if (lingot) {
        lingot.textContent = lingotValue;
        lingot.setAttribute('data-lingot', lingotValue);
      } else {
        console.error("L'élément lingot est invalide ou non défini.");
      }
    } catch (error) {
      console.error("Une erreur s'est produite lors de l'initialisation du lingot :", error);
    }
  }
  
  updateLingout(dom, newLingout) {
    try {
      // Vérification de la validité de la donnée C_LINGOT et assignation d'une valeur par défaut si nécessaire
      const lingotValue = isNaN(parseFloat(newLingout)) ? 0 : Math.round(parseFloat(newLingout));
      
      // Mise à jour du textContent et de l'attribut data-lingot si l'élément lingot existe
      if (dom) {
        dom.textContent = lingotValue;
        dom.setAttribute('data-lingot', lingotValue);
      } else {
        console.error("L'élément lingot est invalide ou non défini.");
      }
    } catch (error) {
      console.error("Une erreur s'est produite lors de l'initialisation du lingot :", error);
    }
  }
  
  initXP(counterValue, data) {
    try {
      // Vérification de la validité des données et assignation de valeurs par défaut si nécessaire
      const xp = isNaN(parseFloat(data.C_XP)) ? 0 : parseFloat(data.C_XP);
      const nextLevel = isNaN(parseFloat(data.C_NEXTLEVEL)) ? 0 : parseFloat(data.C_NEXTLEVEL);
      const sumNextLevel = Math.round(xp + nextLevel);
      
      // Mise à jour du contenu et des attributs si counterValue existe
      if (counterValue) {
        counterValue.innerHTML = Math.round(xp) + '/' + sumNextLevel;
        counterValue.setAttribute('data-xp', Math.round(xp));
        counterValue.setAttribute('data-sumnextlavel', sumNextLevel);
      } else {
        console.error("L'élément counterValue est invalide ou non défini.");
      }
    } catch (error) {
      console.error("Une erreur s'est produite lors de l'initialisation de XP :", error);
    }
  }
  
  updateXP(dom, newXp, newNextLevel) {
    const xp = Number(newXp);
    const nextLevel = Number(newNextLevel);
    const sumNextLevel = Math.round(xp + nextLevel);
    if (dom) {
      dom.innerHTML = Math.round(xp) + '/' + Math.round(sumNextLevel);
      dom.setAttribute('data-xp', Math.round(xp));
      dom.setAttribute('data-sumnextlavel', sumNextLevel);
    } else {
      console.error("L'élément counterValue est invalide ou non défini.");
    }
  }
  
  initStatus(statusMaxxing, infoBullLogoContainer, infoBullXp, infoBullLingot, logoStatusMaxxingContainer, data,infoBullDesc) {
    try {
      // Vérification que data.STATUT est valide
      const status = data.STATUT ? data.STATUT.toLowerCase() : 'inconnu';
      
      // Mise à jour du texte du statut, avec première lettre en majuscule, et ajout de l'attribut data-status
      if (statusMaxxing) {
        statusMaxxing.textContent = status.charAt(0).toUpperCase() + status.slice(1);
        statusMaxxing.setAttribute('data-status', status.toUpperCase());  // Ajout de l'attribut data-status
      } else {
        console.error("L'élément statusMaxxing est invalide ou non défini.");
      }
      
      // Préparation du chemin de base pour le logo de statut
      const baseLogoSrc = this.assetBaseUrl || '';  // Utilisation d'une valeur par défaut si assetBaseUrl n'est pas défini
      
      // Création et insertion du logo pour statusMaxxing
      if (logoStatusMaxxingContainer) {
        const logoStatusMaxxing = document.createElement('img');
        logoStatusMaxxing.id = 'logo-maxxing';  // Ajout de l'ID
        logoStatusMaxxing.src = baseLogoSrc + status + '.png';  // Source de l'image
        logoStatusMaxxing.alt = 'Status logo';  // Texte alternatif
        
        // Vider le conteneur avant d'insérer un nouveau logo pour éviter les doublons
        logoStatusMaxxingContainer.innerHTML = '';
        logoStatusMaxxingContainer.appendChild(logoStatusMaxxing);
      } else {
        console.error("L'élément logoStatusMaxxingContainer est invalide ou non défini.");
      }
      
      // Création et insertion du logo pour infoBullLogo
      if (infoBullLogoContainer) {
        const infoBullLogo = document.createElement('img');
        infoBullLogo.src = baseLogoSrc + status + 'Logo.png';  // Source de l'image
        infoBullLogo.alt = 'Info Bull Logo';  // Texte alternatif
        
        // Vider le conteneur avant d'insérer un nouveau logo pour éviter les doublons
        infoBullLogoContainer.innerHTML = '';
        infoBullLogoContainer.appendChild(infoBullLogo);
      } else {
        console.error("L'élément infoBullLogoContainer est invalide ou non défini.");
      }
      
      if (data.SUPERPOUVOIR && typeof data.SUPERPOUVOIR === 'object' && Object.keys(data.SUPERPOUVOIR).length > 0) {
        infoBullDesc.classList.remove('d-none');
        // Mise à jour ou ajout du span pour la partie XP
        if (infoBullXp && data.SUPERPOUVOIR.XP) {
          let spanXp = infoBullXp.querySelector('span');
          if (spanXp) {
            spanXp.innerHTML = `+ ${data.SUPERPOUVOIR.XP}%`;
          } else {
            infoBullXp.innerHTML = `<span>+ ${data.SUPERPOUVOIR.XP}%</span>` + infoBullXp.innerHTML;
          }
        }

        // Mise à jour ou ajout du span pour la partie Lingot
        if (infoBullLingot && data.SUPERPOUVOIR.LINGOTS) {
          let spanLingot = infoBullLingot.querySelector('span');
          if (spanLingot) {
            spanLingot.innerHTML = `+ ${data.SUPERPOUVOIR.LINGOTS}%`;
          } else {
            infoBullLingot.innerHTML = `<span>+ ${data.SUPERPOUVOIR.LINGOTS}%</span>` + infoBullLingot.innerHTML;
          }
        }
      } else {
      console.log("Aucune donnée dans SUPERPOUVOIR, aucun changement dans infoBull--desc.");
    }
      
    } catch (error) {
      console.error("Une erreur s'est produite dans initStatus :", error);
    }
  }
  
  
  updateStatus(statusMaxxing, infoBullLogoContainer, infoBullXp, infoBullLingot, logoStatusMaxxingContainer, status, xp, lingots, infoBullDesc) {
    try {
      // Mise à jour du texte du statut et de l'attribut data-status
      if (statusMaxxing) {
        const formattedStatus = status.charAt(0).toUpperCase() + status.slice(1).toLowerCase();
        statusMaxxing.textContent = formattedStatus;
        statusMaxxing.setAttribute('data-status', status.toUpperCase());
      } else {
        console.error("L'élément statusMaxxing est invalide ou non défini.");
      }
      
      // Mise à jour du logo du statut dans logoStatusMaxxingContainer
      if (logoStatusMaxxingContainer) {
        const logoStatusMaxxing = logoStatusMaxxingContainer.querySelector('img');
        if (logoStatusMaxxing) {
          logoStatusMaxxing.src = `${this.assetBaseUrl || ''}${status.toLowerCase()}.png`;
          logoStatusMaxxing.alt = 'Status logo';
        } else {
          console.error("Le logo de statut n'a pas été trouvé dans logoStatusMaxxingContainer.");
        }
      } else {
        console.error("L'élément logoStatusMaxxingContainer est invalide ou non défini.");
      }
      
      // Mise à jour du logo dans infoBullLogoContainer
      if (infoBullLogoContainer) {
        const infoBullLogo = infoBullLogoContainer.querySelector('img');
        if (infoBullLogo) {
          infoBullLogo.src = `${this.assetBaseUrl || ''}${status.toLowerCase()}Logo.png`;
          infoBullLogo.alt = 'Info Bull Logo';
        } else {
          console.error("Le logo d'info bull n'a pas été trouvé dans infoBullLogoContainer.");
        }
      } else {
        console.error("L'élément infoBullLogoContainer est invalide ou non défini.");
      }
      
      // Mise à jour des informations XP si elles sont fournies
      if (infoBullXp && xp !== undefined && xp !== 0) {
        infoBullDesc.classList.remove('d-none');
        const xpSpan = infoBullXp.querySelector('span');
        if (xpSpan) {
          xpSpan.textContent = `+ ${xp}%`;
        } else {
          console.error("L'élément span pour XP n'a pas été trouvé dans infoBullXp.");
        }
      } else if (!infoBullXp) {
        console.error("L'élément infoBullXp est invalide ou non défini.");
      }
      
      // Mise à jour des informations Lingots si elles sont fournies
      if (infoBullLingot && lingots !== undefined && lingots !== 0) {
        infoBullDesc.classList.remove('d-none');
        const lingotSpan = infoBullLingot.querySelector('span');
        if (lingotSpan) {
          lingotSpan.textContent = `+ ${lingots}%`;
        } else {
          console.error("L'élément span pour Lingots n'a pas été trouvé dans infoBullLingot.");
        }
      } else if (!infoBullLingot) {
        console.error("L'élément infoBullLingot est invalide ou non défini.");
      }
      
    } catch (error) {
      console.error("Une erreur s'est produite dans updateStatusNotification :", error);
    }
  }
  
  updateProgressBar(progressLevel, niveauBar) {
    if (niveauBar > 99) {
      progressLevel.style.width = "0%";
    } else {
      progressLevel.style.width = niveauBar.toString() + "%";
    }
  }

//++++++++++++++++++++++++++++++++++++++++++++
  
  showLevelUpNotification(txtNewLevel, newLevel, dom) {
    dom.innerHTML += `
                            <div class="EngagementProgramHeader--red-bg nextLevel">
                                <div class="EngagementProgramHeader--scintillement level">
                                    <img class="EngagementProgramHeader--scintillement-img1" src="/bundles/thermorprosite/icons/stars1.svg" />
                                    <img class="EngagementProgramHeader--scintillement-img2" src="/bundles/thermorprosite/icons/stars2.svg" />
                                    <img class="EngagementProgramHeader--scintillement-img3" src="/bundles/thermorprosite/icons/stars3.svg" />
                                    <img class="EngagementProgramHeader--scintillement-img4" src="/bundles/thermorprosite/icons/stars4.svg" />
                                </div>
                                <p>${txtNewLevel} ${newLevel} !</p>
                            </div>
                            `
  };
  
  showStatusNotification(currentStatut, newStatut, dom) {
    dom.innerHTML = `
            <div class="EngagementProgramHeader--red-bg">
                <div class="EngagementProgramHeader--scintillement">
                    <img class="EngagementProgramHeader--scintillement-img1" src="/bundles/thermorprosite/icons/stars1.svg" />
                    <img class="EngagementProgramHeader--scintillement-img2" src="/bundles/thermorprosite/icons/stars2.svg" />
                    <img class="EngagementProgramHeader--scintillement-img3" src="/bundles/thermorprosite/icons/stars3.svg" />
                </div>
                <div class="EngagementProgramHeader--text-animation">
                    <span class="currentStatus"> ${currentStatut} </span>
                    <span class="nextStatus"> ${newStatut} </span>
                </div>
            </div>`;
  };
  
  showLingotRewardNotification = (clingotReward, newLingot, currentLingot, dom) => {
    dom.innerHTML += `
                     <div class="EngagementProgramHeader--red-bg">
                         <span class="EngagementProgramHeader--langoLogo-animation"><img src="/bundles/thermorprosite/icons/lingoWhite.svg" /></span>
                          <div class="EngagementProgramHeader--scintillement">
                              <img class="EngagementProgramHeader--scintillement-img1" src="/bundles/thermorprosite/icons/stars1.svg" />
                              <img class="EngagementProgramHeader--scintillement-img2" src="/bundles/thermorprosite/icons/stars2.svg" />
                              <img class="EngagementProgramHeader--scintillement-img3" src="/bundles/thermorprosite/icons/stars3.svg" />
                              <img class="EngagementProgramHeader--scintillement-img4" src="/bundles/thermorprosite/icons/stars4.svg" />
                          </div>
                          <div class="EngagementProgramHeader--level-coefficient">
                              <span class="lango-coefficient"> ${clingotReward.reward ? clingotReward.reward.toFixed(2) : (newLingot - currentLingot)} </span>
                          </div>
                        </div>`;
  };
  
  showLingotGainNotification = (clingotReward, dom) => {
    dom.innerHTML = `
      <div class="EngagementProgramHeader--red-bg">
      <span class="EngagementProgramHeader--langoLogo-animation"><img src="/bundles/thermorprosite/icons/lingoWhite.svg" /></span>
          <div class="EngagementProgramHeader--scintillement">
            <img class="EngagementProgramHeader--scintillement-img1" src="/bundles/thermorprosite/icons/stars1.svg" />
            <img class="EngagementProgramHeader--scintillement-img2" src="/bundles/thermorprosite/icons/stars2.svg" />
            <img class="EngagementProgramHeader--scintillement-img3" src="/bundles/thermorprosite/icons/stars3.svg" />
            <img class="EngagementProgramHeader--scintillement-img4" src="/bundles/thermorprosite/icons/stars4.svg" />
          </div>
        <div class="EngagementProgramHeader--level-coefficient">
         <span class="lango-coefficient"> ${clingotReward.bonus ? clingotReward.bonus.toFixed(2) : 0} </span>
    </div>
  </div>`;
  };
  
  showXPCoefficientNotification = (cxp_rewardReward, gainCXPPartieEntiereToShow, dom) => {
    dom.innerHTML = `
            <div class="EngagementProgramHeader--red-bg">
                            <div class="EngagementProgramHeader--scintillement level">
                                <img class="EngagementProgramHeader--scintillement-img1" src="/bundles/thermorprosite/icons/stars1.svg" />
                                <img class="EngagementProgramHeader--scintillement-img2" src="/bundles/thermorprosite/icons/stars2.svg" />
                                <img class="EngagementProgramHeader--scintillement-img3" src="/bundles/thermorprosite/icons/stars3.svg" />
                                <img class="EngagementProgramHeader--scintillement-img4" src="/bundles/thermorprosite/icons/stars4.svg" />
                            </div>
                                <div class="EngagementProgramHeader--level-coefficient">
                                    <div class="blurred-overlay"></div>
                                        <span class="level-coefficient">
                                            ${cxp_rewardReward ? cxp_rewardReward.toFixed(2) : gainCXPPartieEntiereToShow}
                                        </span>
                                    </div>
                        </div>`;
  };
  
  //++++++++++++++++++++++++++++++++++++++++++++
  
  
  processApiData(cxpValue, data, cxp_reward, clingot_reward) {
    this.fetchData()
      .then(data => {
        try {
          const delay = 400;
          // Sélection de tous les éléments avec la classe maxxingContainer
          
          const maxxingContainer = document.querySelectorAll('.maxxingContainer');
          if (maxxingContainer.length === 0) {
            console.error("L'élément .maxxingContainer est introuvable.");
            return;
          }
          
          // Nouvelles valeurs de l'API

          const newXp = cxpValue === 0 ? (isNaN(parseFloat(data.C_XP)) ? 0 : parseFloat(data.C_XP)) : cxpValue;
          const newNextLevel = isNaN(parseFloat(data.C_NEXTLEVEL)) ? 0 : parseFloat(data.C_NEXTLEVEL);
          const newSumNextLevel = newXp + newNextLevel;
          const newLingot = isNaN(parseFloat(data.C_LINGOT)) ? 0 : parseFloat(data.C_LINGOT);
          const newStatus = data.STATUT || 'inconnu';
          const newLevel = isNaN(parseFloat(data.NIVEAU)) ? 0 : parseFloat(data.NIVEAU);
          const newSuperpowerXp = (data.SUPERPOUVOIR && !isNaN(parseFloat(data.SUPERPOUVOIR.XP))) ? parseFloat(data.SUPERPOUVOIR.XP) : 0;
          const newSuperpowerLingots = (data.SUPERPOUVOIR && !isNaN(parseFloat(data.SUPERPOUVOIR.LINGOTS))) ? parseFloat(data.SUPERPOUVOIR.LINGOTS) : 0;
          
          
          
          ////////////////////////////////////////////////
          
          
          this.thermorMasterHeader.forEach((item) => {
            // Récupérer les anciennes valeurs

            const currentXp = parseFloat(item.querySelector('.EngagementProgramHeader--lingot span')?.getAttribute('data-xp')) || 0;
            const currentNextLevel = item.querySelector('.EngagementProgramHeader--lingot span')?.getAttribute('data-sumnextlavel') || 0;
            const currentLingot = parseFloat(item.querySelector('.EngagementProgramHeader--langoName span')?.getAttribute('data-lingot')) || 0;
            const currentStatus = item.querySelector('.EngagementProgramHeader--status .EngagementProgramHeader--statusName')?.getAttribute('data-status') || '0';
            const currentLevel = parseFloat(item.querySelector('.EngagementProgramHeader--niveau .desktop-level')?.getAttribute('data-level')) || 0;
  
            // Calcul du nouveau niveau de la barre de progression
            const newNiveauBar = newNextLevel === 0 ? 0 : Math.floor((newXp / (newXp + newNextLevel)) * 100);
            // Calcul le gain XP
            const gainXp = parseFloat(newXp - currentXp).toFixed(2);
  
  
            // Récupération des éléments DOM, évitant de répéter les requêtes
            const nv = item.querySelector('.EngagementProgramHeader--niveau .desktop-level');
            const nvMb = item.querySelector('.EngagementProgramHeader--niveau .mob-level');
            const cxpMaxxing = item.querySelector('.EngagementProgramHeader--niveau .cxp-maxxing')
            const counterValue = item.querySelector('.EngagementProgramHeader--lingot span');
            const statusMaxxing = item.querySelector('.EngagementProgramHeader--status .EngagementProgramHeader--statusName');
            const progressLevels = item.querySelector('.progress-level');
            const lingotWrapper = item.querySelector('.EngagementProgramHeader--lango .lingot-maxxing');
            const lingot = item.querySelector('.EngagementProgramHeader--lango .EngagementProgramHeader--langoName span');
            const logoStatusMaxxingContainer = item.querySelector('.EngagementProgramHeader--statusLogo');
            const infoBullLogoContainer = item.querySelector('.infoBull--logo');
            const infoBullXp = item.querySelector('.infoBull--desc .infoBull--xp');
            const infoBullDesc = item.querySelector('.infoBull--desc ');
            const infoBullLingot = item.querySelector('.infoBull--desc .infoBull--lango');
            
            // popin
            let notifStatusLingots = document.querySelector('.notifeQueteStatut-item-lingots .notifeQueteStatut-coef')?.firstElementChild;
            let notifStatusXp = document.querySelector('.notifeQueteStatut-item-xp .notifeQueteStatut-coef')?.firstElementChild;
            let notifeStatusModal = document.querySelector('.notifeQueteStatut.status');

            
            this.updateXP(counterValue, newXp, newNextLevel);
            this.updateLingout(lingot, newLingot)
            this.updateLevel(nv, nvMb, newLevel, progressLevels, newNiveauBar)
            this.updateStatus(statusMaxxing, infoBullLogoContainer, infoBullXp, infoBullLingot, logoStatusMaxxingContainer, newStatus, newSuperpowerXp, newSuperpowerLingots, infoBullDesc)
            
            /* ****************** LingotMaxxing ************************** */
            
            
            if (parseFloat(currentLingot) !== parseFloat(newLingot)) {
              this.showLingotRewardNotification(clingot_reward, newLingot, currentLingot, lingotWrapper)
              
              if (clingot_reward.bonus) {
                setTimeout(() => {
                  this.showLingotGainNotification(clingot_reward, lingotWrapper)
                }, delay);
              }
            }
            
            
            /* ************************** levelMaxxing  */
            
            
            let passNewLevel = item.classList.contains('FullHeaderTop-item--horizonLogo-mobile') ? "Bravo, niveau" : "Vous avez atteint le niveau";
            
            if (parseFloat(currentLevel) !== newLevel) {
              cxpMaxxing.innerHTML = ""
              this.showLevelUpNotification(passNewLevel, newLevel, cxpMaxxing)
            }
            
            /* **************************  StatusMaxxing   */
            
            if (currentStatus !== newStatus) {
  
              cxpMaxxing.innerHTML = ""
              this.showStatusNotification(currentStatus, newStatus, cxpMaxxing)
  
  
              notifStatusLingots.innerHTML = `${newSuperpowerLingots}%`;
              notifStatusXp.innerHTML = `${newSuperpowerXp}%`;
              document.querySelector('.notifeQueteStatut-item-xp').closest('.notifeQueteStatut-bonus').closest('.super-power').style.display = 'block';
  
              // Change aria-hidden to "false" to show the modal
              if (notifeStatusModal.getAttribute('aria-hidden') === 'false') {
                  setTimeout(function () {
                    notifeStatusModal.setAttribute('aria-hidden', 'true');
                  }, 5000);
              }
              
              if ((newSuperpowerLingots === 0 || newSuperpowerXp === 0) || (newSuperpowerLingots === '' || newSuperpowerXp === '')) {
                document.querySelector('.notifeQueteStatut.status').classList.add('zeroBonus');
                document.querySelector('.infoBull--desc').style.display = 'none';
              }
  
            }
            
            
            /* ************************** xpMaxxing   */
            
            if (currentXp !== newXp && gainXp !== 0) {
              const gainCXPPartieEntiere = Math.floor(gainXp);
              const gainCXPPartieEntiereToShow = gainCXPPartieEntiere === 0 ? "0.00" : gainCXPPartieEntiere.toFixed(2);
              const gainCXPPartieDecimale = parseFloat(gainXp - gainCXPPartieEntiere).toFixed(2);
              cxpMaxxing.innerHTML = ""
              this.showXPCoefficientNotification(cxp_reward.reward, gainCXPPartieEntiereToShow, cxpMaxxing)
              if (cxp_reward.bonus || gainCXPPartieDecimale > 0) {
                setTimeout(() => {
                  this.showXPCoefficientNotification(cxp_reward.bonus, gainCXPPartieDecimale, cxpMaxxing)
                }, delay);
              }
            }
            
          })
          
          
          ///////////////////////////////////////////////
          
          
        } catch (error) {
          console.error("Une erreur s'est produite dans apiMaxxing :", error);
        }
      })
      .catch(error => {
        console.error("Erreur lors de la récupération des données de l'API :", error);
      });
  }
  
  apiMaxxing(cxpValueOrData, cxp_reward = {}, clingot_reward = {}) {
    let cxpValue, data;
    
    // Vérification si le premier argument est un nombre ou un objet
    if (typeof cxpValueOrData === 'number' || typeof cxpValueOrData === 'string') {
      cxpValue = cxpValueOrData;
      // Appel de l'API pour récupérer les données
      this.fetchData()
        .then(fetchedData => {
          data = fetchedData;
          this.processApiData(cxpValue, data, cxp_reward, clingot_reward);
        })
        .catch(error => {
          console.error("Erreur lors de la récupération des données de l'API :", error);
        });
    }
    else if (typeof cxpValueOrData === 'object') {
      // Si un objet est fourni, on l'utilise comme données
      data = cxpValueOrData;
      cxpValue = isNaN(parseFloat(data.C_XP)) ? 0 : parseFloat(data.C_XP); // Utilisation de C_XP de l'objet data
      this.processApiData(cxpValue, data, cxp_reward, clingot_reward);
    }
    else {
      console.error("Le premier argument doit être soit un nombre, soit un objet de données.");
    }
  }
  
  sendPostRequest(dataToSend) {
    if (this.thermorMasterHeader.length > 0) {
      const url = '/info-doc';
      fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(dataToSend)
      })
        .then(response => response.json())
        .then(data => {
          if (data.webTransaction_request_response_code.ticket_msg.value != "") {
            let targets = data.webTransaction_request_response_code.ticket_msg.value.split(/\r?\n/);
            this.apiTarget(targets)
          }
          let rewards = data.webTransaction_request_response_code.rewards;
          if (rewards) {
            let counters = data.webTransaction_request_response_code.counters.counter;
            let totalXpRewards = counters.filter((counter) => counter.name == "C_CUMULXP");
            let totalLingotsRewards = counters.filter((counter) => counter.name == "C_LINGOT");
            let cxpRewards = rewards.filter((reward) => reward.counter_name == "C_XP");
            let clingotRewards = rewards.filter((reward) => reward.counter_name == "C_LINGOT");
            let xpReward = this.extractRewards(totalXpRewards, cxpRewards);
            let lingotRewards = this.extractRewards(totalLingotsRewards, clingotRewards);
            
            if (xpReward.reward > 0 || lingotRewards.reward > 0) {
              this.apiMaxxing(data,xpReward,lingotRewards);
            }
          }
        })
        .catch(error => {
          console.error(error);
        });
    }
  }
  
  extractRewards(totalValue, data) {
    let reward = 0;
    let bonus = 0;
    let total = 0;

    const amounts = data
      .filter(function (obj) {
        for (var i = 0; i < obj.information.length; i++) {
          if (obj.information[i].name === "DISPLAY_BENEFITS") {
            return Boolean(obj.information[i].value);
          }
        }
        return false;
      })
      .map(obj => parseFloat(obj.amount));

    if (totalValue.length > 0) {
      total = parseFloat(totalValue[0].value) - parseFloat(totalValue[0].value_before);
      if (amounts.length > 0) {
        bonus = Math.min(...amounts);
      }
      reward = total - bonus
    }

    return {reward: reward, bonus: bonus};
  }
  
  apiTarget(targets) {
    let notifeQueteModal = document.querySelector('.notifeQueteStatut.quete');
    let notifeQueteModalTitle = document.querySelector('.notifeQueteStatut.quete .notifeQueteStatut-title');
    let notifeSubQueteModal = document.querySelector('.notifeSubQueteStatut.subquete');
    let notifeSubQueteModalTitle = document.querySelector('.notifeSubQueteStatut.subquete .notifeSubQueteStatut-title');
    let notifeStatusModal = document.querySelector('.notifeQueteStatut.status');
    let NotifeBadge = document.querySelector('.NotifeBadge');
    let NotifeBadgeMessage = document.querySelector('.NotifeBadge-message');
    let NotifeBadgeImg = document.querySelector('.NotifeBadge-visuel img');
    
    let dataToSend = {
      codes: targets
    };
    fetch('/info-target', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest'
      },
      body: JSON.stringify(dataToSend)
    })
      .then(response => response.json())
      .then(data => {
        let trophies = data.TROPHIES;
        let subquests = data.SUBQUESTS;
        let quests = data.QUESTS;
        for (const [key, value] of Object.entries(trophies)) {
          NotifeBadgeMessage.textContent = "Vous venez de remporter le badge \"" + value.NAME + "\""
          NotifeBadgeImg.setAttribute('src', value.IMAGE_ON_TEXT);
          NotifeBadgeImg.setAttribute('style', 'max-width:100px;');
          NotifeBadge.setAttribute('style', 'display:block;');
        }
        for (const [key, value] of Object.entries(subquests)) {
          notifeSubQueteModalTitle.textContent = value.NAME + " - " + value.description;
          notifeSubQueteModal.setAttribute('aria-hidden', 'true');
        }
        for (const [key, value] of Object.entries(quests)) {
          if (value.NAME) {
            notifeQueteModalTitle.textContent = value.NAME;
            notifeQueteModal.setAttribute('aria-hidden', 'true');
          }
        }
      })
  }
}

export default MaxxingLib;










